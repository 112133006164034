import { Typography, Card, CardContent, List, ListItem, Box } from '@material-ui/core';

const TermsCard = () => {
	return (
		<>
			<Card width={80}>
				<CardContent>
					<Box m={6}>
						<Typography color="textPrimary" gutterBottom variant="h2" >
							TERMS AND CONDITIONS
						</Typography>
						<Typography color="textPrimary" gutterBottom variant="h5" pb={2}>
							OF SERVICE AND USE OF VECTOR REPORTS' ASSESSMENTS AND REPORTS
						</Typography>

						<Typography color="textSecondary" pb={2}>
							These Terms and Conditions of Service and Use constitute the agreement between you and
							Vector Reports™ with respect to the Assessment and Report Content and Services. Your
							continued access to, purchase of, and/or use of Assessment and Report constitutes your
							acceptance of these Terms and Conditions of Service and Use and any revisions thereto, but it
							does not create a Consultant-Client relationship or other professional services relationship
							between you and Vector Reports™.
						</Typography>
						<Typography color="textSecondary" pb={2}>
							By purchasing or taking an Assessment, you explicitly agree not to hold Vector Reports™
							liable in any way for damages arising from decisions you make based on the information
							available in the Assessments and/or Reports. On decision making matters for which
							assistance is needed, Vector Reports™ strongly urges you to meet with a professional advisor
							who (unlike Vector Reports™) has a professional relationship with you and who (again, unlike
							Vector Reports™) knows the relevant details of your situation.
						</Typography>

						<Typography color="textPrimary" sx={{ fontWeight: 600 }} py={2}>
							Intellectual Property
						</Typography>
						<List>
							<ListItem>
								<Typography color="textSecondary">1. You understand that you are free to reproduce or share your Report information, with
									anyone you deem appropriate, in either electronic form or paper copy provided that all
									original copyright notices, and trade and service marks, remain intact and appear on all
									reproductions thereof, and provided that such Reports have not been edited or modified
									in any way, and, with the restriction that no third party may publish any of the
									information contained in a Vector Reports™ Assessment Report, in any form, without
									written permission from Vector Reports™.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">2.The Assessment and Report Content and Services are proprietary to Vector Reports™. The
									Assessment and Report Content and Services are protected by intellectual property laws
									including, without limitation, copyright and trademark law and may not be used except as
									provided in these Terms and Conditions of Service and Use.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">3. You will not copy the Assessment booklet or duplicate, decompile, disassemble, or reverse
									engineer the Vector Reports™ instrument, or any of its paper or digital components,
									including scored items, or create any derivative works based on the Vector Reports™
									technology, or assist anyone in doing so, without express written permission from Vector
									Reports™.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">4. Vector Reports™ retains all the rights of this website, the Assessment and subsequent
									Report content and process. You are granted the right to view and use this information solely for your own internal use. Only agencies, consultants and mentors may utilize the
									results for a commercial purpose to provide services to their individual clients who have
									had their entity evaluated by a Vector Reports TM Assessment. You may not, however,
									publish, distribute, modify, transmit, reuse, re-post, or use the content of the site,
									including text, image, audio, and video, for public or other commercial purposes without
									written permission from Vector Reports.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">5. You may not remove, alter, cover, or distort any copyright, trademark, or other
									proprietary rights notice on the Vector Reports™ Content and Services. Except for the
									non-exclusive and non-transferable limited-use rights granted to you in these Terms and
									Conditions of Service and Use, you shall not acquire any right, title or interest in the
									Vector Reports™ Content and/or Services. Any rights not expressly granted in these Terms
									and Conditions of Service and Use are expressly reserved.
								</Typography>
							</ListItem>
						</List>

						<Typography color="textPrimary" sx={{ fontWeight: 600 }} py={2}>
							Privacy Policy
						</Typography>
						<List>
							<ListItem>
								<Typography color="textSecondary">6. Entities being evaluated by a Vector Reports TM Assessment understand that in the course
									of selecting rankings or ratings, they may be exposing process status information about
									their company that may be considered confidential and/or competition sensitive. Vector
									Reports™ will take reasonable steps to protect the confidentiality of the data you provide
									including your personal contact information, the responses you provide to Assessment
									rankings, and your customized Report recommendations and will not disclose any of that
									data to any third party. Vector Reports™ through its website provides the assessed
									entity’s Report to only the current owner and assessor at the time the Assessment was
									taken. Vector Reports™ does not monitor nor control distribution of Assessment results
									by owners or assessors. Each entity that possesses a Report is responsible for protecting
									that information from unauthorized disclosure to other parties.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">7.Owners (entities who control access to Assessments) and assessors (entities who have
									taken Assessments) are allowed to view, print and download Reports generated of their
									previously taken Assessments, except that owners of Assessments that have not yet been
									taken may give up their access rights to a Report by transferring ownership to another
									entity that becomes the new owner. Assessors who may have previously had access to an
									Assessment that has been transferred may be replaced by another assigned assessor prior
									to the Assessment being taken, which means only the identified assessor who actually
									participated in the evaluation process will be granted access.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">8. Vector Reports™ will endeavor to maintain access by owners and assessors to their
									associated Reports for no more than two years because these Assessments represent a
									“right now” set of current-state conditions and later on only serve as an indicator of
									progress. There may be other circumstances where these Reports may not be available.
									Owners and assessors are encouraged to download and save their own Assessment
									results in a timely manner.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">9. Vector Reports™ will endeavor to prevent unauthorized access to Assessment results
									through its website. You agree to prevent disclosure of Assessment result information in
									your possession to unauthorized individuals.</Typography></ListItem>
							<ListItem>
								<Typography color="textSecondary">10. Vector Reports™ will take reasonable steps to protect the confidentiality of the data you
									provide. Vector Reports™ may at its discretion publish non-identifiable, aggregated data
									that does not identify individuals, teams or organizations, in any venue or format
									including but not limited to published research, website information, blog posts,
									presentations, training, demonstrations, videos, news releases and other forms of
									publicity.
								</Typography>
							</ListItem>
						</List>

						<Typography color="textPrimary" sx={{ fontWeight: 600 }} py={2}>
							Prohibited Use
						</Typography>
						<List>
							<ListItem>
								<Typography color="textSecondary">11.You may not use the Website, its Content and/or Services in any way prohibited by these
									Terms and Conditions of Service and Use. You will not create, deliver or take an
									Assessment using a paper copy of the Assessment Booklet except one furnished by Vector
									Reports™ that includes a duly assigned, unique Access Code on its accompanying Data
									Sheet.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">12.Your use of the Website, its Content and/or Services is subject to all applicable
									international, federal, state and local laws and regulations.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">13. You may not use the Website, its Content and/or Services for any illegal purpose or in any
									manner which could damage, disable, or impair the Website, its Content and/or Services
									or interfere with any other party’s use and enjoyment of the Website, its Content and/or
									Services.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">14. You may not obtain or attempt to obtain any of Vector Reports’ materials or information
									through any means not intentionally made available or provided through the Website.
								</Typography>
							</ListItem>
						</List>

						<Typography color="textPrimary" sx={{ fontWeight: 600 }} py={2}>
							Disclaimer of Warranties
						</Typography>
						<List>
							<ListItem>
								<Typography color="textSecondary">15. User acknowledges that all ranked or rated input in an Assessment is at the sole
									determination of user or user’s employees or agents and is, therefore, out of the control
									of Vector Reports™. Since these rankings and ratings provide the basis for subsequent
									Reports and/or recommendations, Vector Reports™ makes no warranty that any of the
									content and/or services are suitable for any particular purpose, including pre-employment
									screening, personality assessment, predicting behaviors, abilities and/or skills, or future
									business outcomes. Vector Reports™ cannot guarantee and does not promise any
									suitability of, or any specific results from use of, the Vector Reports™ Content and
									Services. Should you be unhappy with your results your sole and exclusive remedy is to
									stop use and ignore results.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">16. User acknowledges the results of this Assessment are based upon the assessor’s individual
									knowledge of the processes used by the assessor’s team or organization. As such,
									supplying inaccurate ranking or rating information can and will likely produce inaccurate
									results. However, since all recommendations are based in sound, proven business
									practices, user understands that the effect of an inaccurate ranking or rating would be a
									recommendation to work on solid improvement practices, although probably in a less
									efficient sequence.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">17. Access to the Vector Reports™ Website and Services is provided on an “as available” basis.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">18. Vector Reports™, and its officers, directors, employees, parent company, partners,
									successors, agents, content providers, affiliates, subsidiaries, and their related companies
									(collectively the “Parties”) disclaim any and all warranties relating to the Vector Reports™
									Content and Services, whether express or implied including, without limitation, any
									warranties of title, merchantability, fitness for a particular purpose, or non-infringement;
									and warranties concerning the availability, accuracy, security, usefulness, or informational
									content in the Vector Reports™ Content and Services.
								</Typography>
							</ListItem>
						</List>

						<Typography color="textPrimary" sx={{ fontWeight: 600 }} py={2}>
							Limitation of Liability
						</Typography>
						<List>
							<ListItem>
								<Typography color="textSecondary">19. To the maximum extent permitted by applicable law, in no event will Vector Reports TM be
									liable to you or any third party for any indirect, consequential, exemplary, incidental,
									special or punitive damages arising from, relating to, or in any way connected with the
									Vector Reports™ Content and/or Services, including, without limitation, damages for loss
									of use, data or profits, arising out of or in any way connected with the use or performance
									of the Website, Content and/or Services, with the delay or inability to use the Website,
									Content and/or Services, for breach of information including without limitation personal
									information you provided to Vector Reports™ or submitted on the Website, whether
									based on contract, tort, negligence, strict liability or otherwise, even if Vector Reports™ is
									aware or has been advised of the possibility of such damages.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">20. In no event will total liability of Vector Reports™ under or related to these Terms and
									Conditions of Service and Use, the termination thereof, and/or the provision of the
									website and/or content and/or service, regardless of the form of action, exceed the fees
									we actually received from you during the one (1) year period preceding the incident giving
									rise to such liability.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">21. If you are subject to a technical glitch or did not receive the access code for funds paid,
									Vector Reports™ will either issue a replacement access code or refund the money paid (at
									Vector Reports’ discretion). And, if a refund has been provided, the access code
									associated with that refunded Assessment may be blocked from future access.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">22. If you are dissatisfied with any portion of the website and/or content and/or services,
									and/or with any of these Terms and Conditions of Service and Use, your sole and exclusive
									remedy is to discontinue use of the website, Content and Services.
								</Typography>
							</ListItem>
						</List>

						<Typography color="textPrimary" sx={{ fontWeight: 600 }} py={2}>
							Modifications of These Terms and Conditions of Service and Use
						</Typography>
						<List>
							<ListItem>
								<Typography color="textSecondary">23. Vector Reports™ reserves the right, to make revisions to these Terms and Conditions of
									Service and Use from time to time, in its sole discretion. The updated Terms and
									Conditions of Service and Use are available to the User on the Website. Please check the
									Terms of Service and Use before using the Website, Content and/or Services to determine
									whether any changes have been made thereto. If you do not agree to any changes, please
									terminate these Terms and Conditions of Service and Use immediately by (a) notifying
									Vector Reports™ that you are unwilling to accept the changes to the Terms and Conditions
									of Service and Use, and (b) discontinuing your access to and use of Website, its Content
									and Services. Your continued access to and/or use of the Website, Content and/or
									Services constitutes your acceptance of these Terms and Conditions of Service and Use
									and any revisions thereto.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">24. Vector Reports™ reserves the right, in its sole discretion, to make changes to or
									discontinue Content and/or Services, or portions thereof, including, without limitation,
									changing pricing of Products and Services, with or without notice to you, and without
									liability to you or any third party.
								</Typography>
							</ListItem>
						</List>

						<Typography color="textPrimary" sx={{ fontWeight: 600 }} py={2}>
							Applicable Law
						</Typography>
						<List>
							<ListItem>
								<Typography color="textSecondary">25. To the maximum extent permitted by law, this agreement regarding the Terms and
									Conditions of Service and Use is governed by the laws of the State of Arizona and you
									hereby consent to the exclusive jurisdiction and venue of courts in Tucson, Arizona, in all
									disputes arising out of or relating to the use of the Web Site, Content and/or Services. Use
									of the Website, Content and/or Services is unauthorized in any jurisdiction that does not
									give effect to all provisions of these terms and conditions, including without limitation this
									paragraph.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">26. Performance of this agreement by Vector Reports™ is subject to existing laws and legal
									process, and nothing contained in this agreement is in derogation of Vector Reports’ right
									to comply with governmental, court and law enforcement requests or requirements
									relating to your use of the Website, Content and/or Services or information provided to or
									gathered by Vector Reports™ with respect to such use.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">27. If any part of this agreement is determined to be invalid or unenforceable pursuant to
									applicable law including, but not limited to, the warranty disclaimers and liability
									limitations set forth above, then the invalid or unenforceable provision will be deemed
									superseded by a valid, enforceable provision that most closely matches the intent of the
									original provision and the remainder of the agreement shall continue in effect.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">28. Unless otherwise specified herein, these Terms and Conditions of Service and Use
									constitutes the entire agreement between User and Vector Reports™ with respect to the
									Website, Content and/or Services and it supersedes all prior or contemporaneous
									communications and proposals, whether electronic, oral or written, between the user and
									Vector Reports™ with respect to the Website, Content and/or Services.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">29. A printed version of this agreement in English and of any notice given in electronic form
									shall be admissible in judicial or administrative proceedings based upon or relating to this
									agreement to the same extent and subject to the same conditions as other business
									documents and records originally generated and maintained in printed form.
								</Typography>
							</ListItem>
						</List>

						<Typography color="textPrimary" sx={{ fontWeight: 600 }} py={2}>
							Correction of Inaccurate Content and/or Information
						</Typography>
						<List>
							<ListItem>
								<Typography color="textSecondary">30. In the event that Vector Reports™ becomes aware of any inaccurate representation of any
									Vector Reports™ Report or the information contained therein, in any manner or venue,
									public or private, whether by you or any third party, Vector Reports™ may, at its
									discretion, request the correction or removal of that information.
								</Typography>
							</ListItem>
							<ListItem>
								<Typography color="textSecondary">31. You agree to cooperate by immediately correcting or removing any such information if
									within your control, or to assist in requiring any third party to promptly correct or remove
									that information. Vector Reports™ may at its discretion disclose alternative information
									that corrects the inaccuracy, and disclose whether a user has been found to inaccurately
									represent any of the information contained in any Vector Reports™ Report. Should any
									inaccurate representation of any Vector Reports™ Report or the information contained
									therein, in any manner or venue, public or private, whether by you or any third party, we
									may elect to correct that information in the original.
								</Typography>
							</ListItem>
						</List>
					</Box>
				</CardContent>
			</Card>
		</>
	);
}

export default TermsCard;